var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"my-4"},[_c('strong',[_vm._v(_vm._s(_vm.$t('forms.domain.hardware.title')))])]),(_vm.domain.limitedHardware)?_c('p',{staticClass:"text-danger font-weight-bold"},[_c('b-icon',{staticClass:"mr-2",attrs:{"variant":"danger","icon":"exclamation-triangle-fill"}}),_vm._v(" "+_vm._s(_vm.$t('forms.domain.hardware.warning'))+" "),_c('b-row',_vm._l((Object.entries(_vm.domain.limitedHardware)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('b-col',{key:key,attrs:{"cols":"12","xl":"2"}},[_c('ul',[_vm._v(" "+_vm._s(_vm.hardwareWarningTitle[key])+" "),(['videos', 'boot_order', 'isos', 'vgpus', 'floppies'].includes(key))?_c('li',[_vm._l((value['old_value']),function(change){return _c('span',{key:change['id']},[_vm._v(" "+_vm._s(change['name'])+" ")])}),(Array.isArray(value['new_value']) && value['new_value'].length > 0)?_c('b-icon',{attrs:{"icon":"arrow-right"}}):_vm._e(),_vm._l((value['new_value']),function(change){return _c('span',{key:change['id']},[_vm._v(" "+_vm._s(change['name'])+" ")])})],2):_c('li',[_c('span',[_vm._v(" "+_vm._s(value['old_value'])+" ")]),_c('b-icon',{attrs:{"icon":"arrow-right"}}),_c('span',[_vm._v(" "+_vm._s(value['new_value'])+" ")])],1)])])}),1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","xl":"2"}},[_vm._v(" "+_vm._s(_vm.$t("forms.domain.hardware.vcpus"))+" "),_c('v-select',{attrs:{"options":_vm.availableHardware.vcpus,"label":"name"},on:{"search:blur":_vm.v$.vcpus.$touch},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"id":"vcpus"}},'input',attributes,false),events))]}}]),model:{value:(_vm.vcpus),callback:function ($$v) {_vm.vcpus=$$v},expression:"vcpus"}}),(_vm.v$.vcpus.$error)?_c('div',{staticClass:"text-danger",attrs:{"id":"vcpusError"}},[_vm._v(" "+_vm._s(_vm.$t(("validations." + (_vm.v$.vcpus.$errors[0].$validator)), { property: ("" + (_vm.$t("forms.domain.hardware.vcpus"))) }))+" ")]):_vm._e()],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","xl":"2"}},[_vm._v(" "+_vm._s(_vm.$t("forms.domain.hardware.memory"))+" "),_c('v-select',{attrs:{"options":_vm.availableHardware.memory,"label":"name"},on:{"search:blur":_vm.v$.vcpus.$touch},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"id":"memory"}},'input',attributes,false),events))]}}]),model:{value:(_vm.memory),callback:function ($$v) {_vm.memory=$$v},expression:"memory"}}),(_vm.v$.memory.$error)?_c('div',{staticClass:"text-danger",attrs:{"id":"memoryError"}},[_vm._v(" "+_vm._s(_vm.$t(("validations." + (_vm.v$.memory.$errors[0].$validator)), { property: ("" + (_vm.$t("forms.domain.hardware.memory"))) }))+" ")]):_vm._e()],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","xl":"2"}},[_vm._v(" "+_vm._s(_vm.$t("forms.domain.hardware.videos"))+" "),_c('v-select',{attrs:{"options":_vm.availableHardware.videos,"label":"name","reduce":function (element) { return element.id; }},on:{"search:blur":_vm.v$.vcpus.$touch},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"id":"videos"}},'input',attributes,false),events))]}}]),model:{value:(_vm.videos),callback:function ($$v) {_vm.videos=$$v},expression:"videos"}}),(_vm.v$.videos.$error)?_c('div',{staticClass:"text-danger",attrs:{"id":"videosError"}},[_vm._v(" "+_vm._s(_vm.$t(("validations." + (_vm.v$.videos.$errors[0].$validator)), { property: ("" + (_vm.$t("forms.domain.hardware.videos"))) }))+" ")]):_vm._e()],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","xl":"2"}},[_vm._v(" "+_vm._s(_vm.$t("forms.domain.hardware.boot"))+" "),_c('v-select',{attrs:{"options":_vm.availableHardware.bootOrder,"label":"name","reduce":function (element) { return element.id; }},on:{"search:blur":_vm.v$.vcpus.$touch},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"id":"bootOrder"}},'input',attributes,false),events))]}}]),model:{value:(_vm.bootOrder),callback:function ($$v) {_vm.bootOrder=$$v},expression:"bootOrder"}}),(_vm.v$.bootOrder.$error)?_c('div',{staticClass:"text-danger",attrs:{"id":"bootOrderError"}},[_vm._v(" "+_vm._s(_vm.$t(("validations." + (_vm.v$.bootOrder.$errors[0].$validator)), { property: ("" + (_vm.$t("forms.domain.hardware.boot"))) }))+" ")]):_vm._e()],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","xl":"2"}},[_vm._v(" "+_vm._s(_vm.$t("forms.domain.hardware.disk-bus"))+" "),_c('v-select',{attrs:{"options":_vm.availableHardware.diskBus,"label":"name","reduce":function (element) { return element.id; }},on:{"search:blur":_vm.v$.vcpus.$touch},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"id":"diskBus"}},'input',attributes,false),events))]}}]),model:{value:(_vm.diskBus),callback:function ($$v) {_vm.diskBus=$$v},expression:"diskBus"}}),(_vm.v$.diskBus.$error)?_c('div',{staticClass:"text-danger",attrs:{"id":"diskBusError"}},[_vm._v(" "+_vm._s(_vm.$t(("validations." + (_vm.v$.diskBus.$errors[0].$validator)), { property: ("" + (_vm.$t("forms.domain.hardware.disk-bus"))) }))+" ")]):_vm._e()],1),(_vm.showDiskSize)?_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","xl":"2"}},[_vm._v(" "+_vm._s(_vm.$t("forms.domain.hardware.disk-size"))+" "),_c('v-select',{attrs:{"options":_vm.availableHardware.diskSize,"label":"name"},on:{"search:blur":_vm.v$.diskSize.$touch},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"id":"diskSize"}},'input',attributes,false),events))]}}],null,false,3222167906),model:{value:(_vm.diskSize),callback:function ($$v) {_vm.diskSize=$$v},expression:"diskSize"}}),(_vm.v$.diskSize.$error)?_c('div',{staticClass:"text-danger",attrs:{"id":"diskSizeError"}},[_vm._v(" "+_vm._s(_vm.$t(("validations." + (_vm.v$.diskSize.$errors[0].$validator)), { property: ("" + (_vm.$t("forms.domain.hardware.disk-size"))) }))+" ")]):_vm._e()],1):_vm._e(),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","xl":_vm.showDiskSize ? '10' : '12'}},[_vm._v(" "+_vm._s(_vm.$t("forms.domain.hardware.interfaces"))+" "),_c('v-select',{attrs:{"options":_vm.availableHardware.interfaces,"label":"name","close-on-select":false,"multiple":true,"reduce":function (element) { return element.id; }},on:{"search:blur":_vm.v$.vcpus.$touch},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"id":"interfaces"}},'input',attributes,false),events))]}}]),model:{value:(_vm.interfaces),callback:function ($$v) {_vm.interfaces=$$v},expression:"interfaces"}}),(_vm.v$.interfaces.$error)?_c('div',{staticClass:"text-danger",attrs:{"id":"interfacesError"}},[_vm._v(" "+_vm._s(_vm.$t(("validations." + (_vm.v$.interfaces.$errors[0].$validator)), { property: ("" + (_vm.$t("forms.domain.hardware.interfaces"))) }))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }